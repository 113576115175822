import {getFontStyleSheetPath} from '@Libraries/font-library';
import opentype from 'opentype.js';
import {fetchAs} from '@Utils/browser.util';

/**
 * object to cache urls extracted from css files. Maps font family strings to urls
 * @type {object}
 */
const fontFileUrlCache: Record<string, string> = {};

/**
 * character codes such as new-line, backspace, space.
 * Will not trigger invalid font check. Start,end are inclusive
 * @type {{start: number, end: number}}
 */
const ignoredCharCodeRange = {
  start: 0,
  end: 32,
};

/**
 * name commonly used by font files for glyph used when actual glyph is not present
 * @type {string}
 */
const NOT_DEFINED_NAME = '.notdef';

export const DEFAULT_FONT_FAMILIES = '"Nunito Sans", Helvetica, Arial, sans-serif';

const getUrlFromCss = async (cssUrl: string, formatPreference: Array<string>): Promise<string> => {
  let match;
  const cssData = await fetchAs(cssUrl, 'text', 4000);
  if (!cssData) {
    return '';
  }
  for (let i = 0; i < formatPreference.length; i++) {
    const format = formatPreference[i];
    const formatRegex = new RegExp(`.*format\\(['"]${format}['"]\\)`, 'i');
    match = (cssData as string).match(formatRegex);
    if (match) {
      break;
    }
  }
  if (!match) {
    return '';
  }
  const urlRegex = /url\(['"]\S*['"]\)/i;
  match = match[0].match(urlRegex);
  if (!match) {
    return '';
  }
  const url = match[0].replace(/url/g, '').replace(/[()'"]/g, '');
  return /http/.test(url) ? url : relativeToFullUrl(url, cssUrl);
};

/**
 * @param {string} char
 * @returns {boolean}
 */
function isIgnoredChar(char: string): boolean {
  return char.charCodeAt(0) >= ignoredCharCodeRange.start && char.charCodeAt(0) <= ignoredCharCodeRange.end;
}

/**
 * appends relative url to root url extracted from source url. Uses last occuring '/' in source for extraction
 */
function relativeToFullUrl(rel: string, sourceUrl: string): string {
  const src = sourceUrl.slice(0, sourceUrl.lastIndexOf('/') + 1);
  return src + rel;
}

/**
 * returns url to the .ttf, .woff etc file specified according to format preference.
 * Returns empty string if no valid url is found
 */
export const getFontFileUrl = async (family: string, formatPreference: Array<string>, useCache = true): Promise<string> => {
  if (useCache && Object.prototype.hasOwnProperty.call(fontFileUrlCache, family)) {
    return fontFileUrlCache[family];
  }

  if (family === 'bullets') {
    return `${window.PMW.util.asset_url('fonts/bullets.woff')}`;
  }

  let fontUrl = getFontStyleSheetPath(family);
  if (!/http/.test(fontUrl)) {
    return '';
  }
  if (/\.css/.test(fontUrl)) {
    fontUrl = await getUrlFromCss(fontUrl, formatPreference);
  }
  if (fontUrl) {
    fontFileUrlCache[family] = fontUrl;
    return fontUrl;
  }
  return '';
};

/**
 * Only available during generation. Checks whether the font contains glyphs for all characters in the text.
 * Fetches and opens font file to extract and compare character glyphs.
 */
export const fontSupportsText = async (family: string, text: string): Promise<boolean> => {
  try {
    const url = await getFontFileUrl(family, ['woff', 'truetype']);
    if (!url) {
      return false;
    }

    const font = await opentype.load(url);
    let glyph;
    const hasInvalidChar = Array.from(text).some((letter) => {
      if (isIgnoredChar(letter)) {
        return false;
      }
      glyph = font.charToGlyph(letter);
      return !glyph || !glyph.unicodes.length || glyph.name === NOT_DEFINED_NAME;
    });

    return !hasInvalidChar;
  } catch (e) {
    console.error(`could not check text support for font ${family}`);
    console.error('error details: ', e);
    return false;
  }
};

export const getFontFamilyStyleString = (fontFamily: string): string => {
  return `${fontFamily}, ${DEFAULT_FONT_FAMILIES}`;
}